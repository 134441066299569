<template>
    <v-card flat>
        <v-card-title>
            <span class="secondary--text font-weight-bold">Tables</span>
            <v-spacer></v-spacer>
            <v-menu :close-on-content-click="false" :nudge-width="100" :max-width="650" offset-y left>
                <template v-slot:activator="{ on: menu }">
                    <v-btn text color="secondary" v-on="{ ...menu }" class="mr-2">
                        <v-icon small left>mdi-book-open-variant</v-icon>
                        connection guide
                    </v-btn>
                </template>
                <v-card style="overflow-y: hidden">
                    <v-card-title><span class="secondary--text font-weight-bold">Connection guide for external applications</span></v-card-title>
                    <v-card-text v-if="currentSpaceType !== spaceTypes.VENDOR_SPACE">
                        <div class="d-flex flex-column">
                            <span class="subtitle-2 secondary--text">
                                If you want to connect to the database with a 3rd party application, please consult Nuvolos documentation
                                <a
                                    href="https://docs.nuvolos.cloud/features/database-integration/access-data-from-applications#accessing-data-tables-from-external-non-nuvolos-applications"
                                    target="_blank">
                                    here
                                </a>
                                .
                            </span>
                            <span class="subtitle-2 secondary--text">In any case, you'll need the following credentials to connect:</span>
                            <v-row v-for="(item, index) in connectionGuideData" :key="index" align="center" no-gutters>
                                <v-col class="font-weight-bold text-uppercase secondary--text" cols="3">{{ item.item }}</v-col>
                                <v-col class="subtitle-2" cols="9">
                                    <div class="d-flex align-center">
                                        {{ item.data }}
                                        <copy-to-clipboard
                                            :textToCopy="item.data"
                                            buttonClass="caption font-weight-bold"
                                            buttonColor="primary"
                                            buttonName="copy to clipboard"
                                            :iconButton="true"></copy-to-clipboard>
                                    </div>
                                </v-col>
                            </v-row>
                            <span class="subtitle-2 secondary--text">
                                Once connected, execute the following SQL command to activate the schema corresponding to the currently selected state:
                            </span>
                            <div class="d-flex justify-space-between align-center wrap-flex" style="background-color: rgba(23, 168, 193, 0.1); width: 100%">
                                <span class="mb-0 mt-0 ml-2 font-weight-bold caption" style="color: rgba(0, 0, 0, 0.54)">SQL Editor</span>
                                <copy-to-clipboard
                                    :textToCopy="connectionQuery"
                                    buttonClass="caption font-weight-bold"
                                    buttonColor="primary"
                                    :isTextButton="true"
                                    buttonName="copy SQL command to clipboard"></copy-to-clipboard>
                            </div>
                            <CodeHighlight language="sql" :code="connectionQuery" />
                        </div>
                    </v-card-text>
                    <v-card-text v-else-if="currentSpaceType === spaceTypes.VENDOR_SPACE">
                        <v-alert type="warning" prominent text>
                            You cannot access vendor datasets directly from 3rd party applications. To access data with an external application, stage the
                            tables you want to work with and distribute them to a course/research space.
                        </v-alert>
                    </v-card-text>
                </v-card>
            </v-menu>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        text
                        color="secondary"
                        v-on="on"
                        class="mr-2"
                        href="https://docs.nuvolos.cloud/features/database-integration/upload-data"
                        target="_blank">
                        <v-icon small left>menu_book</v-icon>
                        import data
                    </v-btn>
                </template>
                <span>Read the documentation on how to import and upload data to Nuvolos</span>
            </v-tooltip>
            <v-dialog v-model="deactivateDialog" width="500" persistent v-if="isSpaceAdmin && tablesStatus === tableStatusOptions.ACTIVATED_TABLES">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on">Deactivate tables</v-btn>
                </template>
                <v-card>
                    <v-card-title>Deactivating tables</v-card-title>
                    <v-card-text>
                        <v-alert text type="warning">
                            Deactivating tables
                            <b>deletes all table data</b>
                            from all instances of this space,
                            <b>including snapshots!</b>
                        </v-alert>
                        <v-alert text type="error" class="mb-0">
                            This operation is
                            <b>irreversible!</b>
                            <div>Please confirm to proceed.</div>
                        </v-alert>
                        <dashboard-error v-if="deactivateError" :msg="deactivateError"></dashboard-error>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="deactivateDialog = false" :disabled="deactivateLoading">Cancel</v-btn>
                        <v-btn color="error" @click="deactivate()" :loading="deactivateLoading" v-if="!deactivateSuccess">Deactivate</v-btn>
                        <v-btn color="success" @click="deactivateDialog = false" v-else><v-icon>mdi-check</v-icon></v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card-title>
        <v-card-text>
            <template v-if="orgTablesStatus === true && tablesStatus === tableStatusOptions.ACTIVATED_TABLES">
                <v-tabs ref="tabs" color="secondary" class="mt-3 tabs-grey-border-bottom" v-model="tab">
                    <v-tab v-for="tab in tabs" :key="tab.tabName">
                        <v-icon class="mr-1" small>{{ tab.icon }}</v-icon>
                        {{ tab.tabName }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item v-for="tabItem in tabs" :key="tabItem.tabName">
                        <component :is="tabItem.component"></component>
                    </v-tab-item>
                </v-tabs-items>
            </template>
            <v-banner v-else-if="tablesStatus === tableStatusOptions.ACTIVATING_TABLES" single-line>
                <v-avatar slot="icon" color="white" size="60">
                    <v-icon x-large icon="info" color="info">info</v-icon>
                </v-avatar>
                <div class="d-flex flex-column">
                    <span class="font-weight-bold">Tables are being activated</span>
                    <span>Table activation can take a few minutes.</span>
                    <div class="d-flex align-center mt-2">
                        <v-icon color="info" small>mdi-arrow-right</v-icon>
                        <span class="caption font-weight-bold info--text">
                            Upon completion of table activation, running applications need to be restarted in order to integrate the tables feature.
                        </span>
                    </div>
                </div>
            </v-banner>
            <v-banner v-else-if="tablesStatus === tableStatusOptions.INACTIVE_TABLES" single-line>
                <v-avatar slot="icon" color="white" size="60">
                    <v-icon x-large icon="info" color="info">info</v-icon>
                </v-avatar>
                <div class="d-flex flex-column">
                    <span class="font-weight-bold">Tables not activated</span>
                    <div>
                        The tables feature hasn't been activated for this
                        <span v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE">course</span>
                        <span v-else-if="currentSpaceType === spaceTypes.RESEARCH_SPACE">research project</span>
                        <span v-else-if="currentSpaceType === spaceTypes.VENDOR_SPACE">dataset</span>
                        yet.
                        <span v-if="!isSpaceAdmin">Contact a space administrator to request the activation of tables.</span>
                        <span v-else-if="!tablesCanBeEnabled">Contact {{ whoEnablesFeature[tablesDisabledReason] }} to enable it.</span>
                    </div>
                </div>
                <template v-if="isSpaceAdmin && tablesCanBeEnabled" v-slot:actions>
                    <v-btn small color="info" outlined @click="activateTable">activate tables</v-btn>
                </template>
            </v-banner>
            <v-card v-else-if="!tablesFetching && orgTablesStatus === false && !isTrialSpace" flat>
                <v-card-text>
                    <v-alert type="info" prominent text>
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">Tables not activated</span>
                            The current plan does not allow for the usage of Tables in Nuvolos. Please reach out to your organization administrator if you
                            require this feature.
                        </div>
                    </v-alert>
                </v-card-text>
            </v-card>
            <v-card v-else-if="!tablesFetching" flat>
                <v-card-text>
                    <v-alert type="info" prominent text>
                        <div class="d-flex flex-column">Data on tables status is currently not avaiable.</div>
                    </v-alert>
                </v-card-text>
            </v-card>
            <v-card flat v-if="isTrialSpace">
                <v-card-text>
                    <v-alert type="info" prominent text>
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">You discovered a premium feature!</span>
                            Subscribing to Nuvolos will unlock it.
                        </div>
                    </v-alert>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { queryData } from '@/mixins/query'
import eventBus from '@/components/EventBus'
import { fetchTask } from '@/apis'

const DashboardError = () => import('@/components/DashboardError.vue')
const CodeHighlight = () => import('@/components/CodeHighlight')
const TheSnapshotQueryBuilder = () => import('../components/TheSnapshotQueryBuilder')
const TheSnapshotTableList = () => import('../components/TheSnapshotTableList')
const TheSnapshotTableQueries = () => import('../components/TheSnapshotTableQueries')
const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')

export default {
    name: 'snapshot-tables',
    components: { TheSnapshotQueryBuilder, TheSnapshotTableList, TheSnapshotTableQueries, CopyToClipboard, CodeHighlight, DashboardError },
    mixins: [enumsData, queryData],
    data() {
        return {
            tab: 0,
            tabValues: {
                TABLES: 0,
                QUERYBUILDER: 1,
                QUERIES: 2
            },
            queryUpdater: null,
            tableStatusOptions: {
                ACTIVATED_TABLES: 'ACTIVE',
                ACTIVATING_TABLES: 'ACTIVATING',
                INACTIVE_TABLES: 'INACTIVE',
                EMPTY_TABLES: 'EMPTY'
            },
            tablePollingInterval: null,
            tablesCanBeEnabled: false,
            tablesDisabledReason: '',
            whoEnablesFeature: {
                TURNED_OFF_RESOURCE_POOL: `this space's resource pool manager`,
                TURNED_OFF_ORG: "this space's organization manager",
                DISABLED: 'our support'
            },
            deactivateDialog: false,
            deactivateError: '',
            deactivateSuccess: false,
            deactivateLoading: false
        }
    },
    created() {
        eventBus.$on('querySubmitSuccess', evt => {
            this.tab = this.tabValues.QUERIES
        })
    },
    computed: {
        ...mapGetters('spaceStore', ['currentSpaceType', 'isSpaceAdmin', 'isTrialSpace']),
        ...mapState('snapshotStore', ['tablesPath', 'tablesFetching']),
        ...mapState('userStore', ['snowflakeTokens']),
        ...mapGetters('orgStore', ['orgTablesStatus']),
        ...mapState('orgStore', ['spacesWithPrimarySnapshots']),
        ...mapGetters('snapshotStore', ['isDevelopment']),
        connectionQuery() {
            const db = this.tablesPath.db
            const schema = this.tablesPath.schema
            return 'USE SCHEMA "' + db + '"' + '."' + schema + '"'
        },
        spaceTableData() {
            if (this.orgTablesStatus === true && this.spacesWithPrimarySnapshots) {
                const currentSpaceData = this.spacesWithPrimarySnapshots.find(space => space.sid.toString() === this.$route.params.sid.toString())
                if (currentSpaceData) {
                    return currentSpaceData
                }
            }
            return null
        },
        tablesStatus() {
            if (this.spaceTableData) {
                if (this.spaceTableData.space_lock_mode_name === this.$data.tableStatusOptions.ACTIVATING_TABLES && !this.tablesFetching) {
                    return this.$data.tableStatusOptions.ACTIVATING_TABLES
                } else if (this.spaceTableData.has_tables === null && this.isDevelopment && !this.tablesFetching) {
                    return this.$data.tableStatusOptions.INACTIVE_TABLES
                } else if (this.spaceTableData.has_tables) {
                    return this.$data.tableStatusOptions.ACTIVATED_TABLES
                } else if (
                    this.spaceTableData.has_tables === null &&
                    this.spaceTableData.space_lock_mode_name !== this.$data.tableStatusOptions.ACTIVATING_TABLES &&
                    !this.isDevelopment &&
                    !this.tablesFetching
                ) {
                    return this.$data.tableStatusOptions.EMPTY_TABLES
                }
            }
            return null
        },
        connectionGuideData() {
            const username = this.$store.state.userInfo.username
            const password = this.snowflakeTokens.access_token
            const schema = this.tablesPath.schema
            const database = this.tablesPath.db
            const hostname = 'alphacruncher.eu-central-1.snowflakecomputing.com'
            const fullData = [
                { item: 'username', data: username },
                { item: 'password', data: password },
                { item: 'schema', data: schema },
                { item: 'database', data: database },
                { item: 'hostname', data: hostname }
            ]
            return fullData
        },
        tabs() {
            return [
                { tabName: 'Tables', icon: 'mdi-table-large', component: 'TheSnapshotTableList' },
                { tabName: 'Query builder', icon: 'filter_alt', component: 'TheSnapshotQueryBuilder' },
                { tabName: 'Queries', icon: 'query_stats', component: 'TheSnapshotTableQueries' }
            ]
        }
    },
    methods: {
        async deactivate() {
            try {
                this.deactivateLoading = true
                const { data } = await this.$axios.delete(`spaces/${this.$route.params.sid}/delete_tables_async`)
                const taskResult = await fetchTask(data.tkid)
                if (taskResult instanceof Error) {
                    this.deactivateError = taskResult.message
                } else {
                    this.deactivateSuccess = true
                    await new Promise(resolve => setTimeout(() => resolve(), 2000))
                    this.deactivateDialog = false
                    this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid)
                    this.resetIntervals()
                }
            } catch (error) {
                this.deactivateError = 'Deactivate failed because of network error.'
            } finally {
                this.deactivateLoading = false
            }
        },
        clearTableStatusInterval() {
            clearInterval(this.tablePollingInterval)
            this.tablePollingInterval = null
        },
        clearQueriesInterval() {
            clearInterval(this.queryUpdater)
            this.queryUpdater = null
        },
        setQueriesInterval() {
            this.$data.queryUpdater = setInterval(() => {
                if (this.$auth.isAuthenticated && this.tablesStatus === this.tableStatusOptions.ACTIVATED_TABLES) {
                    this.$store.dispatch('snapshotStore/fetchQueries', this.$route.params.snid)
                }
            }, 5000)
        },
        activateTable() {
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Activating tables...',
                snackBarTimeout: 10000,
                snackBarIcon: 'info'
            })
            this.$axios.get(`/spaces/${this.$route.params.sid}/activate_async`).then(response => {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Table activation has started...',
                    snackBarTimeout: 10000,
                    snackBarIcon: 'info'
                })
                this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid)
                this.setTableStatusPollingInterval()
            })
        },
        fetchTableStatus() {
            this.$axios.post('/spaces/find', { oid: this.$route.params.oid, sid: this.$route.params.sid }).then(response => {
                if (response.data && response.data[0].has_tables && this.tablePollingInterval !== null) {
                    this.clearTableStatusInterval()
                    this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid)
                } else if (
                    response.data &&
                    response.data[0].lock_mode_name === this.tableStatusOptions.ACTIVATING_TABLES &&
                    this.tablePollingInterval === null
                ) {
                    this.setTableStatusPollingInterval()
                }
            })
        },
        checkRouteQuery() {
            if (this.$route.query.tab && this.$route.query.tab === this.tableViewTabNames.TABLES) {
                this.tab = this.tabValues.TABLES
            } else if (this.$route.query.tab && this.$route.query.tab === this.tableViewTabNames.QUERIES) {
                this.tab = this.tabValues.QUERIES
            } else if (this.$route.query.tab && this.$route.query.tab === this.tableViewTabNames.QUERYBUILDER) {
                this.tab = this.tabValues.QUERYBUILDER
            } else if (this.$route.query.tab !== this.tableViewTabNames.TABLES) {
                this.$router.push({ query: { tab: this.tableViewTabNames.TABLES } })
            }
        },
        setRouteQuery(nextTab) {
            if (nextTab === this.tabValues.QUERIES && this.$route.query.tab !== this.tableViewTabNames.QUERIES) {
                this.$router.push({ query: { tab: this.tableViewTabNames.QUERIES } })
            } else if (nextTab === this.tabValues.QUERYBUILDER && this.$route.query.tab !== this.tableViewTabNames.QUERYBUILDER) {
                this.$router.push({ query: { tab: this.tableViewTabNames.QUERYBUILDER } })
            } else if (nextTab === this.tabValues.TABLES && this.$route.query.tab !== this.tableViewTabNames.TABLES) {
                this.$router.push({ query: { tab: this.tableViewTabNames.TABLES } })
            } else if (!this.$route.query.tab) {
                this.$router.push({ query: { tab: this.tableViewTabNames.TABLES } })
            }
        },
        setTableStatusPollingInterval() {
            this.clearTableStatusInterval()
            this.tablePollingInterval = setInterval(() => {
                this.fetchTableStatus()
            }, 10000)
        },
        async fetchTablesFeatureStatus() {
            try {
                const { data } = await this.$axios.get(`/spaces/${this.$route.params.sid}/can_enable_tables`)
                this.tablesCanBeEnabled = data.can_enable_tables
                this.tablesDisabledReason = data.reason
            } catch (error) {
                console.log('error:', error)
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Could not retrieve table activation status.`,
                    snackBarTimeout: 2000,
                    snackBarIcon: 'error'
                })
            }
        }
    },
    watch: {
        tab: function (to) {
            setTimeout(() => {
                if (this.$refs.tabs) {
                    this.$refs.tabs.onResize()
                }
            }, 300)
            this.setRouteQuery(to)
        },
        $route: {
            handler(to, from) {
                if (
                    to.name === 'snapshot-tables' &&
                    to.query &&
                    to.query.tab === this.tableViewTabNames.QUERYBUILDER &&
                    this.tab !== this.tabValues.QUERYBUILDER
                ) {
                    this.tab = this.tabValues.QUERYBUILDER
                }
                if (
                    from &&
                    from.params.snid &&
                    (!to || !to.params.snid || (to && to.params.snid && from.params.snid.toString() !== to.params.snid.toString()))
                ) {
                    this.tab = this.tabValues.TABLES
                }
            }
        }
    },
    mounted() {
        this.$store.dispatch('userStore/fetchSnowflakeTokens')
        this.fetchTablesFeatureStatus()
    },
    resetIntervals() {
        this.clearQueriesInterval()
        this.clearTableStatusInterval()
    },
    activated() {
        this.fetchTableStatus()
        this.$store.dispatch('snapshotStore/setTablesPath', this.$route.params.snid)
        this.$store.dispatch('snapshotStore/fetchQueries', this.$route.params.snid)
        this.setQueriesInterval()
        this.checkRouteQuery()
    }
}
</script>
